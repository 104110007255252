import React, { memo } from 'react';
import Popover from '../profile/popover';
import { Icon } from '@common/components/icon';
import { combineClassNames } from '@utils/combineClassNames';
import { Popup } from 'semantic-ui-react';

import type { SimpleUser } from '../../types/objects';
import { Emoji } from 'emoji-mart';
import { useFormattedUserStatus } from '@modules/core/hooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type UserStatusLabelProps = {
  status: { text: string, expires_at: null | string }
};

const formatExpiresAt = (expiresAtString: string, language: string, t: any) => {
  const expiresAt = moment(expiresAtString);
  if (expiresAt.isSame(new Date(), 'day')) {
    return expiresAt.format('HH:mm');
  }
  return (
    expiresAt.locale(language).format('LL') +
    ' ' + t('dates:at') + ' ' +
    expiresAt.format('HH:mm')
  );
};

export const UserStatusLabel = memo(({ status }: UserStatusLabelProps) => {
  const { t, i18n: { language } } = useTranslation();
  return (
    <span>
      { status.text }
      {
        status.expires_at &&
        <>
          {' '}{ t('core:user_status_until') }{' '}
          { formatExpiresAt(status.expires_at, language, t) }
        </>
      }
    </span>
  );
});

type UserStatusBadgeProps = {
  formattedStatus: any;
  profileImageSize: number;
};

export const UserStatusBadge = memo(({
  formattedStatus,
  profileImageSize
}: UserStatusBadgeProps) => {
  return (
    <Popup
      position="left center"
      basic
      size="small"
      offset={[0, -5]}
      trigger={
        <div
          className="statusBadge"
          style={{
            padding: Math.floor(profileImageSize / 12),
            bottom: Math.floor(profileImageSize / 20) * -1,
            left: Math.floor(profileImageSize / 10) * -1
          }}
        >
          <Emoji
            emoji={formattedStatus?.emoji.colons}
            size={Math.floor(profileImageSize / 3)}
          />
        </div>
      }
      content={<UserStatusLabel status={formattedStatus} />}
    />
  );
});

type Props = {
  user: Partial<SimpleUser>;
  size?: number;
  className?: string;
  enablePopover?: boolean;
  onEdit?: (user: Partial<SimpleUser>, e: React.MouseEvent) => void;
  onClick?: React.MouseEventHandler;
  status?: Record<string, any> | null;
};

const defaultUser: SimpleUser = {
  id: '',
  full_name: '"Verwijderde gebruiker"',
  profile_img: null,
  last_active: null,
};

export const ProfileImage = ({
  user = defaultUser,
  size = 40,
  className,
  enablePopover,
  onEdit,
  onClick,
  status = null
}: Props) => {
  const fullClassName = combineClassNames('ProfileImage Image', 'fs-exclude', className, {
    'ProfileImage--edit': !!onEdit,
  });

  const handleClick = (e: any) => {
    onEdit?.(user, e);
    onClick?.(e);
  };

  const formattedStatus = useFormattedUserStatus(status);

  const image = (
    <div
      className={fullClassName}
      style={{ width: size, height: size }}
      onClick={handleClick}
      role="button"
    >
      <img
        style={{
          width: size,
          height: size,
        }}
        src={user.profile_img || ''}
        alt={user.full_name}
      />
      {onEdit && (
        <div className="ProfileImage__Edit">
          <Icon type="edit__filled" />
        </div>
      )}
      {
        formattedStatus ?
          <UserStatusBadge
            formattedStatus={formattedStatus}
            profileImageSize={size}
          /> :
          null
      }
    </div>
  );

  // @ts-expect-error
  return enablePopover ? <Popover user={user}>{image}</Popover> : image;
};

export default ProfileImage;
