import React from 'react';
import { useTranslation } from 'react-i18next';

import { EStatus } from '@common/definitions';
import { EPulseSurveyStatus } from '@modules/survey/types/objects';
import { ESurveyStatus } from '@modules/survey/definitions';

import './styles.scss';

export type BadgeProps = {
  status: EStatus | EPulseSurveyStatus | ESurveyStatus;
};

// Can be expanded to show other types of badges besides status
const Badge = ({ status }: BadgeProps) => {
  const { t } = useTranslation();
  return (
    <div className={`Badge Badge--${status}`}>
      {t('common:status', { context: status })}
    </div>
  );
};

export default Badge;
