import React from 'react';
import moment, { Moment } from 'moment';

import { DateInput } from '@common/components/form/inputs/date';
import TimePickerInput from '../timepicker';

export type DateTimePickerInputOwnProps = {
  value?: Moment | null;
  min?: Moment | null;
  onChange: (date: Moment | null) => void;
};

export const DateTimePickerInput = ({ value, min, onChange }: DateTimePickerInputOwnProps) => {
  if (!value) onChange(moment());

  const onChangeDate = (newValue: Moment | null) => {
    if (!newValue) return;
    const newStateValue = moment(value)
      .year(newValue.year())
      .month(newValue.month())
      .date(newValue.date());
    onChange(newStateValue);
  };

  const onChangeTime = (newValue: string) => {
    const [hour, minute] = newValue.split(':');
    const newStateValue = moment(value)
      .hours(+hour)
      .minutes(+minute)
      .seconds(0);
    onChange(newStateValue);
  };

  const minValue = min || moment();

  return (
    <div className="DateTimePicker">
      <DateInput
        inline
        className="Form__control DatePicker"
        calendarClassName="inline"
        value={value}
        onChange={onChangeDate}
        minDate={minValue}
      />

      <div className="Form__block">
        <div className="Form__block__container Form__block__container--padding-large">
          <div className="DateTimePicker__Date">{value && value.format('dddd DD MMMM')}</div>

          <TimePickerInput
            value={(value && value.format('HH:mm')) || minValue.format('HH:mm')}
            onChange={onChangeTime}
            min={moment().isSame(value, 'date') ? minValue.format('HH:mm') : undefined}
          />
        </div>
      </div>
    </div>
  );
};
