import React from 'react';
import { ProfileImage } from './profile-image';
import './profile-images-group.scss';

export default function ProfileImagesGroup(props: any) {
  const { users, size = 30, limit = 3 } = props;
  return (
    <div className="ProfileImagesGroup">
      {
        users.slice(0, limit).map((user: any, i: number) => {
          const className = i > 0 ? 'overlapping' : '';
          return (
            <ProfileImage
              className={className} key={user.id} size={size} user={user}
            />
          );
        })
      }
    </div>
  );
}
