import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import Alert from '../alert';
import { Button } from '../button';

const ErrorComponent = ({ error, t }) => (
  <div className="AsyncSpinner">
    <div>
      <Alert
        type="error"
        title={t('core:template_error')}
      >
        {error.type ? (
          <>
            <b>{error.type}</b>
            <br />
            {error.detail}
          </>
        ) : error.message}
      </Alert>
      <br />
      <br />
      <Button type="primary" size="large" onClick={() => window?.location?.reload?.()}>
        <Trans i18nKey="core:template_error_refresh_button" />
      </Button>
      <Button type="inverted-primary" size="large" onClick={() => window?.history?.back?.()}>
        <Trans i18nKey="core:template_error_back_button" />
      </Button>
      {process.env.INTERCOM_APP_ID && (
        <Button type="inverted-primary" size="large" onClick={() => window.Intercom && window.Intercom('show')}>
          <Trans i18nKey="core:template_error_contact_button" />
        </Button>
      )}
    </div>
  </div>
);

export default withTranslation()(ErrorComponent);
