import React, { memo, PropsWithChildren } from 'react';
import { NavLink, NavLinkProps, Route } from 'react-router-dom';
import Icon from '@common/components/icon';

import type { IconTypes } from '@common/components/icon';
import Tooltip from '@common/components/tooltip';

type Props = {
  children: any;
  title?: string;
};

const SubNavigationBarComponent = ({ children, title }: Props) => (
  <div className="SubNavigationBar">
    {title && <h2>{title}</h2>}
    {children}
  </div>
);

type SubtitleProps = PropsWithChildren<{
  className?: string;
}>;

const Subtitle = memo(({ children, ...props }: SubtitleProps) => {
  return <h3 className="Subtitle" {...props}>{ children }</h3>;
});

SubNavigationBarComponent.Subtitle = Subtitle;

type ItemProps = Omit<NavLinkProps, 'to' | 'className'> & {
  path: string;
  title: string;
  tooltip?: string;
  count?: number;
  exact?: boolean;
  icon?: IconTypes;
  childPaths?: {
    subpath: string;
    label: string;
  }[];
};

SubNavigationBarComponent.Item = ({
  path,
  title,
  count,
  icon,
  tooltip,
  childPaths,
  ...props
}: ItemProps) => {
  return (
    <NavLink
      {...props}
      to={path}
      className="SubNavigationBar__Item"
      activeClassName="SubNavigationBar__Item--active"
    >
      {icon && <Icon type={icon} />}
      <span>{title}</span>
      {count !== undefined && (
      <span className="SubNavigationBar__Item__Count">({ count })</span>
      )}
      {tooltip && (
        <Tooltip title={tooltip}>
          <Icon className="SubNavigationBar__TooltipIcon" type="info" />
        </Tooltip>
      )}

      {
        /*
          this feature is never used in the app, but I'm leaving this here
          because it could come in handy for future development, here you can see how it looks
          https://linear.app/oneteam/issue/ONE-1016/move-the-learning-paths-link-to-a-more-visible-location-[cancelled#comment-7cc1fba8
        */
        childPaths && (
          <Route path={path}>
            {
              childPaths.map((child) => {
                return (
                  <>
                    <div className="break" />
                    <NavLink
                      className="childPath"
                      to={`${path}${child.subpath}`}
                      exact
                    >
                      { child.label }
                    </NavLink>
                  </>
                );
              })
            }
          </Route>
        )
      }
    </NavLink>
  );
};

export const SubNavigationBar = SubNavigationBarComponent;
