import Api from '../../../common/services/api';

export const CORE_RECEIVE_USER = 'core/RECEIVE_USER';

export default (id) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: user } = await Api.get(`/v2/organisations/${selected.id}/users/${id}`);

  return dispatch({
    type: CORE_RECEIVE_USER,
    user,
  });
};
