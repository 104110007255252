import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Select } from '@common/components/form/inputs/select';

class TimePickerInput extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    min: PropTypes.string,
    warning: PropTypes.bool,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  getMinValue() {
    const { value, min } = this.props;

    if (!min) return ['00', '00'];

    let [hour, minute] = min.split(':');
    const [currentHour] = value.split(':');

    if (minute === '59') hour = (parseInt(hour, 10) + 1).toString();
    if (currentHour.toString() !== hour.toString()) minute = '00';

    return [hour, minute];
  }

  createOptions(min, max, step = 1, minValue = 0) {
    return R.range(0, max, step)
      .map((i) => {
        const number = i * step;
        const disabled = number < parseInt(minValue, 10);
        const value = number < 10 ? `0${number}` : `${number}`;

        return { label: value, value, disabled };
      });
  }

  handleChange(key, newValue) {
    const { value, onChange } = this.props;
    const currentValue = value.split(':');
    currentValue[key] = newValue;

    onChange(currentValue.join(':'));
  }

  render() {
    const { value, invalid, warning, disabled } = this.props;

    const [hourValue, minuteValue] = value.split(':');
    const [minHour, minMinute] = this.getMinValue();

    const className = ['TimePicker'];
    if (warning) className.push('TimePicker--warning');
    if (invalid) className.push('TimePicker--invalid');
    if (disabled) className.push('TimePicker--disabled');

    return (
      <div className={className.join(' ')}>
        <Select
          value={hourValue}
          placeholder=""
          options={this.createOptions(0, 24, 1, minHour)}
          onChange={(option) => this.handleChange(0, option.value)}
          clearable={false}
          disabled={disabled}
        />
        <Select
          value={minuteValue}
          placeholder=""
          options={this.createOptions(0, 60, 1, minMinute)}
          onChange={(option) => this.handleChange(1, option.value)}
          clearable={false}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default TimePickerInput;
