import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { EmojiPicker } from '../form/inputs/emoji';
import { Icon } from '../icon';

type UserStatusInputProps = {
  onSelect?: (...args: any[]) => any,
  inputDisabled?: boolean
  text?: string
  onTextChange?: (...args: any[]) => any
  emoji?: any
  placeholder?: string,
  onDelete: (...args: any[]) => any
};

const UserStatusInput = memo(({
  onSelect,
  inputDisabled = false,
  text = '',
  onTextChange,
  emoji,
  placeholder,
  onDelete
}: UserStatusInputProps) => {
  const { t } = useTranslation();
  return (
    <div className="Form__control UserStatusInput">
      <EmojiPicker
        emoji={emoji}
        onSelect={onSelect}
        triggerIcon="insert_emoticon_reaction"
      />
      <input
        placeholder={placeholder || t('core:update_your_status')}
        className="statusText"
        value={text}
        onChange={onTextChange}
        disabled={inputDisabled}
      />
      {
        (emoji || text) ?
          <Icon
            className="statusDelete"
            type="close"
            onClick={onDelete}
          /> :
          null
      }
    </div>
  );
});

export default UserStatusInput;
