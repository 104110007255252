import * as R from 'ramda';
import Api from '../../../common/services/api';
import openConversation from './open-conversation';

export const CHAT_POST_CONVERSATION = 'chat/POST_CONVERSATION';

export default (values) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const payload = { ...values };
  if (payload.name) payload.name = payload.name.trim();

  const { data, is_new: isNew } = await Api.post(`/v3/organisations/${selected.id}/conversations`, payload);

  data.participant_ids = R.filter(R.is(String), data.participant_ids);

  dispatch({
    type: CHAT_POST_CONVERSATION,
    isNew,
    item: data,
  });

  dispatch(openConversation(data.id));
};
