import { useEffect, useRef } from 'react';
import SocketService from '@common/services/socket';
import { useDispatch } from 'react-redux';

export default function useUserStatusRemoteUpdateListener(
  userId: string,
  callback: (...args: any[]) => any
) {
  const callbackRef = useRef(callback);
  const cb = callbackRef.current;
  const pusher = SocketService.getPusher();
  const dispatch = useDispatch();

  // console.log("debug useUserStatusRemoteUpdateListener");

  useEffect(() => {
    if (!pusher || !userId) return;

    const channelName = 'user-' + userId;
    pusher.unsubscribe(channelName);
    // console.log("debug subscribed to " + channelName);
    const channel = pusher.subscribe(channelName);

    channel.bind('user:status:updated', (data: any) => {
      // console.log("debug got message");
      if (!data) return;
      cb(data, dispatch, userId);
    });

    return () => {
      pusher.unsubscribe(channelName);
    };
  }, [userId, pusher, dispatch, cb]);
}
