import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '@common/components/modal';
import { Button } from '@common/components/button';

export const ReferModal = () => {
  const { t } = useTranslation();

  return (
    <Modal
      list
      className="ReferModal"
      title={t('core:refer_oneteam_and_earn')}
      content={(
        <div className="ReferModal__Content">
          <div>
            <img src="/static/images/review.png" alt={t('core:write_review')} />
            <h3>{t('core:write_review')}</h3>
            <div className="ReferModal__Description">
              <Trans
                i18nKey="core:write_review_description"
                components={[
                  <a href={t('core:link_g2_crowd')} target="_blank" rel="noopener noreferrer" />,
                  <a href={t('core:link_how_to_submit')} target="_blank" rel="noopener noreferrer" />,
                ]}
              />
            </div>
            <Button
              type="primary"
              size="large"
              icon="launch"
              onClick={() => window.open(t('core:link_g2_crowd'), '_blank')}
            >
              {t('core:write_a_review')}
            </Button>
          </div>
          <div>
            <img src="/static/images/referral.png" alt={t('core:refer_company')} />
            <h3>{t('core:refer_company')}</h3>
            <div className="ReferModal__Description">
              <Trans i18nKey="core:refer_company_description" />
            </div>
            <Button
              type="primary"
              size="large"
              icon="launch"
              onClick={() => window.open(t('core:link_make_referral'), '_blank')}
            >
              {t('core:make_a_referral')}
            </Button>
          </div>
        </div>
      )}
    >
      <Button size="large" icon="gift" className="ReferModal__Button">
        {t('core:refer_and_earn')}
      </Button>
    </Modal>
  );
};
