import React, { memo, useCallback } from 'react';
import { AlertService } from '@common/services/alert';
import { Api } from '@common/services/api';
import { SET_USER_STATUS, UNSET_USER_STATUS } from '@modules/core/actions';
import { getFormattedUserStatus, getUserStatus } from '@modules/core/selectors/logged-user';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import UserStatusInput from '../user-status/user-status-input';

type UserStatusMenuInputProps = {
  organisationId: string,
  userId: string
};
const UserStatusMenuInput = memo(({
  organisationId,
  userId
}: UserStatusMenuInputProps) => {
  const formattedStatus = useSelector(getFormattedUserStatus);

  const status: any = useSelector(getUserStatus);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onDelete = useCallback(async (event: any) => {
    if (!status) return;

    event.stopPropagation();

    try {
      dispatch({ type: UNSET_USER_STATUS, userId });
      const payload = { status: null };
      await Api.put(`/v3/organisations/${organisationId}/users/me`, payload);
    } catch (error: any) {
      console.log('error', error);
      dispatch({
        type: SET_USER_STATUS,
        emoji: status.emoji,
        expires_at: status.expires_at,
        text: status.text,
        userId
      });
      AlertService.forStatus(error?.status_code, {
        warning: t('core:user_status_unset_error'),
        error: t('core:user_status_unset_error')
      });
    }
  }, [userId, status, dispatch, organisationId, t]);

  return <UserStatusInput
    text={formattedStatus?.text}
    emoji={formattedStatus?.emoji}
    onDelete={onDelete}
    inputDisabled
  />;
});

export default UserStatusMenuInput;
