import { useDispatch } from 'react-redux';

import { useAppSelector } from './redux';
import { selected } from '@modules/organisation/selectors/organisation';
import { EPlanPackageConfig } from '@common/definitions';
import getCurrentPlanPackage from '@common/utils/data/get-current-plan-package';
import { SHOW_PLAN_PACKAGE_UPGRADE_MODAL } from '@modules/core/actions';

type ReturnType = {
  isAvailable: boolean;
  showUpgradeModal: () => void;
};

const getIsAvailable = (allowed: number | boolean, value?: number | boolean): boolean => {
  if (typeof allowed === 'number' && typeof value === 'number') {
    return value < allowed;
  }
  return !!allowed;
};

export const useIsAvailableInPlanPackage = (
  packageId?: `${EPlanPackageConfig}`,
  value?: boolean | number,
): ReturnType => {
  const organisation = useAppSelector(selected);
  const dispatch = useDispatch();

  const ret = {
    isAvailable: true,
    // For now no additional props can be passed, because this is not needed at the moment.
    // If we would like to add this in the future,
    // make sure it doesn't interfere with any event that could be passes as the first argument
    // when this method is, for example, added to the onClick handler of a component.
    showUpgradeModal: () => {
      dispatch({
        type: SHOW_PLAN_PACKAGE_UPGRADE_MODAL,
        packageId,
      });
    }
  };

  const current = getCurrentPlanPackage(organisation);

  // If no plan package is defined, the organisation has not transitioned to a price package yet.
  // Package will be defined by enabled_components and will not be handled by this hook.
  if (!current || !packageId) return ret;

  const isAvailable = getIsAvailable(current[packageId], value);

  return {
    ...ret,
    isAvailable,
  };
};
