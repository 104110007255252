import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Trans, withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { EPermissions, ECompanyDashboardPermissions } from '@common/definitions';
import * as organisationSelector from '@modules/organisation/selectors/organisation';
import type { History, Organisation, Network, LoggedUser } from '@common/types/objects';
import type { StoreState } from '@common/types/store';
import ProfileImage from '../profile-image';
import Dropdown from '../dropdown';
import { Icon } from '../icon';
import ImagePlaceholder from '../image-placeholder';
import SearchBar from '../search-bar';
import ProgressBar from '../progress-bar';
import Permission from '../permission';
import { ReferModal } from './refer';
import UserStatusModal from '../user-status/user-status-modal';
import UserStatusMenuInput from './user-status-menu-input';
import useUserStatusRemoteUpdateListener from '@common/hooks/use-user-status-remote-update-listener';
import { SET_USER_STATUS, UNSET_USER_STATUS } from '@modules/core/actions';
import { getUserStatus } from '@modules/core/selectors/logged-user';

const mapStateToProps = (state: StoreState) => ({
  progress: organisationSelector.setup(state),
});

const SetupProgressComponent = connect(mapStateToProps)(({ history, progress: { percentage } }) => {
  if (percentage === 100) return null;

  return (
    // @ts-expect-error
    <div className="TopBar__SetupProgress" onClick={() => history.push('/admin/setup')} role="button">
      <div className="TopBar__SetupProgress__Title">
        <h5><Trans i18nKey="core:top_bar_starting_guide" /></h5>
        {`${percentage}%`}
      </div>
      <ProgressBar status={percentage} />
    </div>
  );
});

type Props = {
  history: History,
  children: any,
  admin?: boolean,
  organisation: Organisation,
  networks: Array<Network>,
  network: Network | undefined,
  loggedUser: LoggedUser,
  onOpenProfile: (path?: string) => void,
  onLogout: (query?: string) => void,
  onSelectNetwork: (id: string) => Promise<string>,
  setContentRef: (instance: HTMLDivElement | null) => void,
  t: (key: string, values?: Object) => string,
};

const TopBarComponent = ({
  history,
  children,
  admin,
  networks,
  organisation,
  network,
  loggedUser,
  onSelectNetwork,
  setContentRef,
  onOpenProfile,
  onLogout,
  t
}: Props) => {
  const [searchTerm, setSearchTerm] = React.useState(null);

  const items = networks
    // @ts-expect-error
    .filter((n) => !searchTerm || n.name.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((n) => (network && n.id === network.id ? -1 : 1));

  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);

  useUserStatusRemoteUpdateListener(
    loggedUser?.id || '',
    (data: any, dispatch: any, userId: string) => {
      if (data.status) {
        dispatch({
          type: SET_USER_STATUS,
          emoji: data.status.emoji,
          expires_at: data.status.expires_at,
          text: data.status.text,
          userId
        });
      } else {
        dispatch({ type: UNSET_USER_STATUS, userId });
      }
    }
  );

  const userId = loggedUser?.id;

  const status = useSelector(getUserStatus);
  // console.log("debug status", status);

  return (
    <div className="TopBar">
      {(admin || networks.length > 1) && (
        <Dropdown
          search
          className="LocationSelector"
          toggle={(
            <div className="TopBar__Trigger LocationSelector__Trigger">
              {network && !admin ? network.name : t('core:top_bar_location_selector')}
              <Icon type="expand_more" />
            </div>
          )}
          scrolling
        >
          {networks.length > 5 && (
            // @ts-expect-error
            <SearchBar debounce={false} filter={false} onSearch={setSearchTerm} />
          )}
          {items.map((item) => (
            <Dropdown.Item
              key={item.id}
              className={(network && network.id === item.id)
                ? 'LocationSelector__Item LocationSelector__Item--selected'
                : 'LocationSelector__Item'}
              onClick={async () => {
                const path = await onSelectNetwork(item.id);

                history.push(path);
              }}
            >
              <ImagePlaceholder size={32} name={item.name} />
              <div className="LocationSelector__Item__Info">
                <div className="LocationSelector__Item__Name">{item.name}</div>
                <small>
                  <Trans
                    i18nKey="core:top_bar_location_selector_user_count"
                    values={{ count: item.member_count }}
                  />
                </small>
              </div>
              {network && network.id === item.id && <Icon type="check" className="LocationSelector__Item__Check" />}
            </Dropdown.Item>
          ))}
        </Dropdown>
      )}

      <div className="TopBar__Content" ref={setContentRef} />

      {admin && !organisation.trial && (
        <ReferModal />
      )}

      {admin && organisation.trial && (
        <Permission name={EPermissions.ORGANISATION_SETUP_STEPS_UPDATE}>
          {/* @ts-expect-error */}
          <SetupProgressComponent history={history} />
        </Permission>
      )}

      {!admin && (
        <Permission name={ECompanyDashboardPermissions}>
          <NavLink to="/admin" id="topbar_company_dashboard" className="TopBar__Trigger">
            <Trans i18nKey="core:top_bar_company_dashboard" />
          </NavLink>
        </Permission>
      )}

      {/* <ExplanationVideoContext.Consumer>
        {(handler) => handler && (
          <Permission role={ERoleTypes.ADMIN}>
            <div className="TopBar__Trigger TopBar__Trigger--icon" onClick={handler}>
              <Icon type="ondemand_video" />
            </div>
          </Permission>
        )}
      </ExplanationVideoContext.Consumer> */}

      {children}

      <Dropdown
        alignRight
        className="TopBar__Profile"
        toggle={(
          <ProfileImage size={40} user={loggedUser} status={status} />
        )}
      >
        <div role="option" className="item TopBar__Profile__Detail" onClick={() => onOpenProfile()}>
          <ProfileImage size={40} className="pull-left" user={loggedUser} />

          <div className="TopBar__Profile__Detail__Info">
            {loggedUser.full_name}
            <small>
              <Trans i18nKey="core:top_bar_profile_open_profile" />
            </small>
          </div>
        </div>

        <Dropdown.Item
          className="userStatusInputItem"
          onClick={() => setShowStatusModal(true)}
        >
          <UserStatusMenuInput
            userId={userId} organisationId={organisation.id}
          />
        </Dropdown.Item>
        {
          showStatusModal &&
          <UserStatusModal userId={userId} close={() => setShowStatusModal(false)} />
        }

        <Dropdown.Divider />

        <Dropdown.Item
          icon="person"
          onClick={() => onOpenProfile('/edit')}
        >
          <Trans i18nKey="core:profile_settings" />
        </Dropdown.Item>
        <Dropdown.Item
          icon="shield"
          onClick={() => onOpenProfile('/edit/preferences')}
        >
          <Trans i18nKey="core:top_bar_profile_privacy" />
        </Dropdown.Item>
        <Dropdown.Item
          icon="sync_alt"
          onClick={() => history.push('/auth/organisations')}
        >
          <Trans i18nKey="core:top_bar_profile_switch_organisation" />
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          danger
          icon="logout"
          // @ts-expect-error
          onClick={onLogout}
        >
          <Trans i18nKey="core:top_bar_logout" />
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
};

// @ts-expect-error
const RoutedTopBarComponent = withRouter(withTranslation()(TopBarComponent));

export default RoutedTopBarComponent;
