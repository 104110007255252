import moment, { Moment } from 'moment';
import React, {
  memo, useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapModal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { Emoji, emojiIndex } from 'emoji-mart';

import { Select } from '@common/components/form/inputs/select';
import { Button } from '../button';
import { DateTimePickerInput } from '../form/inputs/datetime';
import { Icon } from '../icon';
import UserStatusInput from './user-status-input';
import { Api } from '@common/services/api';
import { selected } from '@modules/organisation/selectors/organisation';
import { AlertService } from '@common/services/alert';
import { SET_USER_STATUS, UNSET_USER_STATUS } from '@modules/core/actions';
import { getFormattedUserStatus } from '@modules/core/selectors/logged-user';
import { StoreState } from '@common/types/store';

const { Header, Body, Footer } = BootstrapModal;

type PresetsPros = {
  setText: (...args: any[]) => any,
  setEmoji: (...args: any[]) => any,
  setClearAfter: (...args: any[]) => any
};

const Presets = memo(({ setText, setEmoji, setClearAfter }: PresetsPros) => {
  const { t } = useTranslation();

  const presets = useMemo(() => {
    return [{
      emoji: emojiIndex.emojis.spiral_calendar_pad,
      text: t('core:user_status_in_a_meeting'),
      clearAfter: '1h',
      clearAfterText: t('core:user_status_1_hour')
    }, {
      emoji: emojiIndex.emojis.tooth,
      text: t('core:user_status_dentist'),
      clearAfter: '1h',
      clearAfterText: t('core:user_status_1_hour')
    }, {
      emoji: emojiIndex.emojis.school,
      text: t('core:user_status_study'),
      clearAfter: 'today',
      clearAfterText: t('dates:today')
    }, {
      emoji: emojiIndex.emojis.face_with_thermometer,
      text: t('core:user_status_sick'),
      clearAfter: 'today',
      clearAfterText: t('dates:today')
    }, {
      emoji: emojiIndex.emojis.palm_tree,
      text: t('core:user_status_vacationing'),
      clearAfter: "don't clear",
      clearAfterText: t('core:user_status_dont_clear')
    }];
  }, [t]);

  return (
    <div className="Presets">
      <p className="presetsTitle">{ t('core:presets') }</p>
      {
        presets.map(({ emoji, text, clearAfter, clearAfterText }: any) => {
          return (
            <div
              role="button"
              key={emoji.id}
              className="preset"
              onClick={() => {
                setEmoji(emoji);
                setClearAfter({ value: clearAfter });
                setText(text);
              }}
            >
              <Emoji emoji={emoji.colons} size={20} />
              <h6>{text}</h6>
              <label> - { clearAfterText }</label>
            </div>
          );
        })
      }
    </div>
  );
});

type DateTimeModalProps = {
  close: (...args: any[]) => any,
  onConfirm: (...args: any[]) => any,
  initValue: any
};

const DateTimeModal = memo(({ close, onConfirm, initValue }: DateTimeModalProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initValue);

  return (
    <BootstrapModal
      className="modal-datetimepicker modal--color-grey Form modal-list"
      show
      onHide={close}
      // @ts-expect-error
      size="small"
    >
      <div className="Modal__Wrapper">
        <Body>
          <DateTimePickerInput
            value={value || moment()}
            onChange={setValue}
          />
        </Body>
        <Footer>
          <div className="pull-right">
            <Button onClick={close}>
              { t('common:carousel_footer_cancel') }
            </Button>
            <Button
              type="primary"
              onClick={() => {
                onConfirm(value);
                close();
              }}
            >
              { t('core:confirm') }
            </Button>
          </div>
        </Footer>
      </div>
    </BootstrapModal>
  );
});


type ClearAfterObj = {
  label?: string,
  value: number | string | null
};

type ClearAfterProps = {
  clearAfter: ClearAfterObj | null,
  setClearAfter: (...args: any[]) => any,
  clearAfterCustom: any,
  setClearAfterCustom: (...args: any[]) => any,
};

const ClearAfter = memo(({
  clearAfter, setClearAfter, clearAfterCustom, setClearAfterCustom
}: ClearAfterProps) => {
  const { t } = useTranslation();

  const [datetimeOpen, setDatetimeOpen] = useState(false);
  const closeDatetime = useCallback(() => {
    setDatetimeOpen(false);
  }, [setDatetimeOpen]);

  const options = useMemo(() => {
    return [{
      label: t('core:user_status_dont_clear'),
      value: "don't clear"
    }, {
      label: t('core:user_status_30_minutes'),
      value: '30m'
    }, {
      label: t('core:user_status_1_hour'),
      value: '1h'
    }, {
      label: t('core:user_status_4_hours'),
      value: '4h'
    }, {
      label: t('dates:today'),
      value: 'today'
    }, {
      label: t('core:user_status_this_week'),
      value: 'week'
    }, {
      label: t('core:user_status_custom'),
      value: 'custom'
    }];
  }, [t]);

  // this mechanism opens the date picker when custom "clear after" is selected
  const clearAfterRef = useRef(clearAfter);
  useEffect(() => {
    if (
      clearAfter?.value === 'custom' &&
      clearAfter?.value !== clearAfterRef.current?.value
    ) {
      setDatetimeOpen(true);
    }
    clearAfterRef.current = clearAfter;
  }, [clearAfter, setDatetimeOpen, clearAfterRef]);

  const locale = useSelector((state: StoreState) => {
    return state.loggedUser.user.language.locale;
  });

  return (
    <div className="ClearAfter">
      <div className="inline-block clearAfterLabel">
        <h6>{ t('core:clear_after') }</h6>
      </div>

      <div className="inline-block clearAfterSelectPart">
        <Select
          value={clearAfter?.value}
          options={options}
          onChange={setClearAfter}
        />
        {
          clearAfter?.value === 'custom' && clearAfterCustom ?
            <a
              className="clearAfterCustom"
              role="button"
              onClick={() => setDatetimeOpen(true)}
            >
              { formatUserStatusDate(clearAfterCustom, locale) }
            </a> :
            null
        }
      </div>
      {
        datetimeOpen ?
          <DateTimeModal
            initValue={clearAfterCustom}
            close={closeDatetime}
            onConfirm={setClearAfterCustom}
          /> :
          null
      }
    </div>
  );
});

function formatUserStatusDate(date: Moment, locale: string) {
  const dateFormat = locale === 'en' ?
    'dddd, Do MMMM YYYY, hh:mm A' : // PM/AM
    'dddd, Do MMMM YYYY, HH:mm'; // 24 hours format
  return date.locale(locale).format(dateFormat);
}

const getExpiresAtMoment = (clearAfter: any, clearAfterCustom: any) => {
  const now = moment();
  switch (clearAfter?.value) {
    case '30m':
      return now.add(30, 'minutes');
    case '1h':
      return now.add(1, 'hours');
    case '4h':
      return now.add(4, 'hours');
    case 'today':
      return now.hours(23).minutes(59).seconds(59);
    case 'week':
      const sunday = moment().isoWeekday(7);
      return sunday.hours(23).minutes(59).seconds(59);
    case 'custom':
      return clearAfterCustom;
    default:
      return null;
  }
};

const getExpiresAt = (clearAfter: any, clearAfterCustom: any) => {
  const mom = getExpiresAtMoment(clearAfter, clearAfterCustom);
  return mom && mom.milliseconds(0).toISOString();
};

type UserStatusModalProps = {
  close: () => void,
  userId: string
};

const UserStatusModal = memo(({ close, userId }: UserStatusModalProps) => {
  const organisation = useSelector(selected);
  const status = useSelector(getFormattedUserStatus);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [emoji, setEmoji] = useState<any>(status?.emoji || null);
  const [text, setText] = useState(status?.text || '');
  const [clearAfter, setClearAfter] = useState<ClearAfterObj | null>(
    status ?
      { value: status.expires_at ? 'custom' : "don't clear" } :
      null
  );
  const [clearAfterCustom, setClearAfterCustom] = useState(
    status?.expires_at ? moment(status.expires_at) : null
  );

  const onTextChange = useCallback((event: any) => {
    setText(event.target.value);
    if (!emoji) {
      setEmoji(emojiIndex.emojis.speech_balloon);
    }
  }, [setText, emoji]);

  const allInputsPopulated = !!(
    emoji && text &&
    (clearAfter?.value === 'custom' ? clearAfterCustom : true)
  );

  const allInputsBlank = (
    emoji === null && text === '' && clearAfter === null &&
    clearAfterCustom === null
  );

  const canSave = allInputsPopulated || allInputsBlank;

  const [loading, setLoading] = useState(false);
  const orgId = organisation.id;

  const onSave = useCallback(async () => {
    try {
      setLoading(true);

      let payload: Record<string, any>;
      if (allInputsPopulated) {
        payload = {
          status: {
            emoji: emoji.native,
            text,
            expires_at: getExpiresAt(clearAfter, clearAfterCustom)
          }
        };
      } else {
        payload = { status: null };
      }

      await Api.put(`/v3/organisations/${orgId}/users/me`, payload);
      if (allInputsPopulated) {
        dispatch({ type: SET_USER_STATUS, ...payload.status, userId });
      } else {
        dispatch({ type: UNSET_USER_STATUS, userId });
      }
      AlertService.success(t('core:user_status_published'));
      close();
    } catch (error) {
      console.log('error', error);
      // @ts-expect-error
      AlertService.forStatus(error?.status_code, {
        warning: t('core:user_status_publish_error'),
        error: t('core:user_status_publish_error')
      });
    } finally {
      setLoading(false);
    }
  }, [
    emoji, text, clearAfter, clearAfterCustom, setLoading, orgId, t, dispatch,
    userId, allInputsPopulated
  ]);

  const onDelete = useCallback(() => {
    setClearAfter(null);
    setClearAfterCustom(null);
    setEmoji(null);
    setText('');
  }, [setClearAfter, setClearAfterCustom, setEmoji, setText]);

  return (
    // @ts-expect-error
    <BootstrapModal show className="UserStatusModal" onHide={close} size="small">
      <div className="Modal__Wrapper">
        <Header>
          <div className="Modal__MainHeader">
            <h2 className="Modal__title">
              { t('core:set_a_status') }
            </h2>
            <Icon className="Modal__HeaderClose" type="close" onClick={close} />
          </div>
        </Header>
        <Body>
          <UserStatusInput
            emoji={emoji}
            onSelect={setEmoji}
            onTextChange={onTextChange}
            text={text}
            placeholder={t('core:what_is_your_status')}
            onDelete={onDelete}
          />
          <ClearAfter
            clearAfter={clearAfter}
            setClearAfter={setClearAfter}
            clearAfterCustom={clearAfterCustom}
            setClearAfterCustom={setClearAfterCustom}
          />
          <Presets
            setText={setText} setEmoji={setEmoji} setClearAfter={setClearAfter}
          />
        </Body>
        <Footer>
          <div className="pull-right">
            <Button onClick={close}>
              { t('common:carousel_footer_cancel') }
            </Button>
            <Button
              type="primary"
              disabled={!canSave}
              onClick={onSave}
              isLoading={loading}
            >
              { t('common:carousel_footer_save') }
            </Button>
          </div>
        </Footer>
      </div>
    </BootstrapModal>
  );
});

export default UserStatusModal;
