import React from 'react';
import moment from 'moment';
import { useRouteMatch, withRouter } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Icon } from '../icon';
import { Button } from '../button';
import Dropdown from '../dropdown';
import Image from '../image';
import Permission from '../permission';
import { EPermissions } from '../../definitions';

import type { History, Organisation } from '../../types/objects';
import type { IconTypes } from '../icon';

type ItemProps = {
  path: string,
  title?: string,
  tooltip?: string,
  icon: IconTypes,
  unread?: number,
  isActive?: (match: any, location: any) => boolean
};

const Item = ({
  path, title, tooltip, icon, unread, isActive, ...props
}: ItemProps) => {
  const location = useLocation();
  const match = useRouteMatch();
  const { pathname } = location;

  return (
    <NavLink
      {...props}
      isActive={isActive}
      to={path}
      className={tooltip ? 'NavigationBar__Item NavigationBar__Item--tooltip' : 'NavigationBar__Item'}
      activeClassName="NavigationBar__Item--active"
    >
      <div className="NavigationBar__Item__Unread__Container">
        {unread ? <span className="NavigationBar__Item__Unread">{unread}</span> : null}
      </div>
      {/* @ts-expect-error */}
      <Icon type={
        pathname.startsWith(path) || (isActive && isActive(match, location)) ?
          `${icon}__filled` :
          icon
      }
      />
      {title}
      {tooltip && (
        <div className="NavigationBar__Item__Tooltip">
          <div className="NavigationBar__Item__Tooltip__Inner">{tooltip}</div>
        </div>
      )}
    </NavLink>
  );
};

type Props = {
  history: History,
  children: any,
  organisation: Organisation,
  onHome: () => void,
  admin?: boolean,
};

const minScrollHeight = 0;
const scrollSpeed = 0.25;
let currentPosition = 0;

const NavigationBarComponent = React.memo<Props>(({
  history, children, organisation, admin, onHome,
}: Props) => {
  // @ts-expect-error
  const addScrollListener = (ref) => {
    if (!ref) return;

    ref.addEventListener('wheel', (e: WheelEvent) => {
      // $FlowFixMe parentNode is always defined
      const maxScrollHeight = ref.offsetHeight - ref.parentNode.offsetHeight;
      currentPosition = Math.max(
        Math.min((currentPosition || 0) - (e.deltaY * scrollSpeed), minScrollHeight),
        maxScrollHeight * -1,
      );

      ref.style.top = `${currentPosition}px`; // eslint-disable-line no-param-reassign

      e.preventDefault(); // prevent body from scrolling
    }, { passive: false });
  };

  const expiration = organisation.trial && moment(organisation.trial.expires_at);
  const daysTillExpiration = expiration && Math.ceil(expiration.diff(moment()) / 86400000);

  return (
    <div className="NavigationBar">
      <div className="NavigationBar__Menu" ref={addScrollListener} style={{ position: 'relative' }}>
        <div className="NavigationBar__OrganisationLogo" onClick={onHome}>
          <Image exclude src={organisation.brand_icon} alt={organisation.name} size={40} />
        </div>
        {children}
        <Dropdown
          pointing="left"
          toggle={(
            <div className="NavigationBar__Item">
              <Icon type="info" />
              <Trans i18nKey="core:help" />
            </div>
          )}
        >
          {admin && organisation.trial && (
            <Permission name={EPermissions.ORGANISATION_SETUP_STEPS_UPDATE}>
              <Dropdown.Item
                icon="school"
                onClick={() => history.push('/admin/setup')}
              >
                <Trans i18nKey="core:startguide" />
              </Dropdown.Item>
            </Permission>
          )}
          {/* {admin && <Dropdown.Item icon="star"><Trans i18nKey="core:what_is_new" /></Dropdown.Item>} */}
          <Dropdown.Item
            icon="info"
            // @ts-expect-error
            onClick={() => window?.open('https://help.oneteam.io', '_blank').focus()}
          >
            <Trans i18nKey="core:knowledge_center" />
          </Dropdown.Item>
          {/* {admin && <Dropdown.Item icon="map"><Trans i18nKey="core:roadmap" /></Dropdown.Item>} */}
          {process.env.INTERCOM_APP_ID && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item
                icon="help"
                onClick={() => window?.Intercom('show')}
              >
                <Trans i18nKey="core:chat_with_us" />
              </Dropdown.Item>
            </>
          )}
        </Dropdown>
        {expiration && (
          <div className="NavigationBar__Expiration">
            <div className="NavigationBar__Expiration__Number">
              <small><Trans i18nKey="core:days_left" /></small>
              {(daysTillExpiration && daysTillExpiration > 0) ? daysTillExpiration : 0}
            </div>
            <Button
              type="primary"
              className="NavigationBar__Expiration__CTA Button"
              onClick={() => window?.Intercom('show')}
            >
              <Trans i18nKey="core:expiration_contact_sales" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
});

const NavigationBar = NavigationBarComponent;

// @ts-expect-error
NavigationBar.Item = Item;
// @ts-expect-error
NavigationBar.Divider = () => <div className="NavigationBar__Divider" />;

// @ts-expect-error
const RoutedNavigationBar = withRouter(NavigationBar);

export default RoutedNavigationBar;
