import PropTypes from 'prop-types';
import React, { Component, Children, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import moment from 'moment';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import ImageItem from '../image-item/large';
import { Button } from '../button';
import * as usersSelector from '../../../modules/core/selectors/users';
import fetchUser from '../../../modules/core/actions/fetch-user';
import createConversation from '../../../modules/chat/actions/post-conversation';

export function useViewUser({
  match, history, loggedUserId, network, user,
}) {
  return useCallback(() => {
    const isCurrent = user.id === loggedUserId;
    if (match.path.indexOf('admin') === 0) {
      history.push(isCurrent ? '/admin/profile/about' : `/admin/users/${user.id}`);
    } else if (network) {
      history.push(isCurrent ? '/profile/about' : `/networks/${network}/users/${user.id}`);
    }
  }, [match, history, loggedUserId, network, user]);
}

export function PopoverContent(props) {
  const {
    user, loggedUserId, network, history, dispatch,
  } = props;

  const { t } = useTranslation();

  const handleViewUser = useViewUser(props);

  const handleOpenChat = () => {
    history.push('/conversations');
    dispatch(createConversation({
      type: 'private',
      participant_ids: [user.id],
    }));
  };

  return (
    <>
      <ImageItem
        imageSize={50}
        image={user.profile_img}
        name={user.full_name}
      >
        <small>
          {[
            user.function,
            user.last_active
            && t('common:profile_popover_last_seen', {
              lastSeen: moment(user.last_active).fromNow(),
            }),
          ].filter((s) => !!s).join(' - ')}
        </small>
      </ImageItem>
      <div className="ProfilePopover__Actions">
        <Button type="primary" size="small" onClick={handleViewUser}>
          <Trans i18nKey="common:profile_popover_show_profile" />
        </Button>
        {!!(network && user.id !== loggedUserId) && user.can_chat && (
          <Button type="primary" size="small" onClick={handleOpenChat}>
            <Trans i18nKey="common:profile_popover_send_private_message" />
          </Button>
        )}
      </div>
    </>
  );
}

class ProfilePopover extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    user: PropTypes.object.isRequired,
    loggedUserId: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    network: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      show: false,
      trigger: false,
      popover: false,
    };

    this.setReference = (ref) => (this.trigger = ref);
    this.handleTriggerEnter = this.handleTriggerEnter.bind(this);
    this.handleTriggerLeave = this.handleTriggerLeave.bind(this);
    this.handlePopoverEnter = this.handlePopoverEnter.bind(this);
    this.handlePopoverLeave = this.handlePopoverLeave.bind(this);
  }

  handleTriggerEnter() {
    this.state.trigger = true;

    setTimeout(() => {
      const { dispatch, user } = this.props;

      if (this.state.trigger && !this.state.show) {
        this.setState({ show: true });

        if (user.should_load) dispatch(fetchUser(user.id));
      }
    }, 500);
  }

  handleTriggerLeave() {
    this.state.trigger = false;

    setTimeout(() => !this.state.popover && this.setState({ show: false }), 500);
  }

  handlePopoverEnter() {
    this.state.popover = true;
  }

  handlePopoverLeave() {
    this.state.popover = false;

    setTimeout(() => !this.state.trigger && this.setState({ show: false }), 500);
  }

  render() {
    const { children, user } = this.props;

    const trigger = React.cloneElement(Children.only(children), {
      onMouseEnter: this.handleTriggerEnter,
      onMouseLeave: this.handleTriggerLeave,
    });

    if (!user) return children;

    return (
      <>
        {!user.should_load && (
          <Overlay
            target={this.trigger}
            show={this.state.show}
            placement="top"
            shouldUpdatePosition
          >
            <Popover
              className="ProfilePopover fs-exclude"
              id={`user-popover-${user.id}`}
              onMouseEnter={this.handlePopoverEnter}
              onMouseLeave={this.handlePopoverLeave}
            >
              <PopoverContent {...this.props} />
            </Popover>
          </Overlay>
        )}
        <a ref={this.setReference} className="ProfilePopover__Trigger">
          {trigger}
        </a>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: usersSelector.byId(state, props.user.id) || props.user,
  network: state.network.selected,
  loggedUserId: state.loggedUser.user.id,
});

export default withRouter(withTranslation()(connect(mapStateToProps)(ProfilePopover)));
